// ButtonZalo.js
import React from 'react';

const styles = {
  button: {
    border: 'none',
    borderRadius: '100%',
    width: '50px',
    height: '50px',
    background: '#0065f7',
    color: '#fff',
    fontWeight: 'bold',
    position: 'fixed',
    display: 'grid',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '12px',
    padding: '0px',
    bottom: '30px',
    right: '50px',
    animation: 'zalo 1000ms infinite',
    zIndex: 999999999,
  },
  hover: {
    opacity: 0.6,
  },
  keyframes: `
    @keyframes zalo {
      0% {
        transform: translate3d(0, 0, 0) scale(1);
        box-shadow: 0 0 0 0px #0065f7, 0 0 0 0px #0065f7;
      }
      33.3333% {
        transform: translate3d(0, 0, 0) scale(0.9);
      }
      66.6666% {
        transform: translate3d(0, 0, 0) scale(1);
      }
      100% {
        transform: translate3d(0, 0, 0) scale(1);
        box-shadow: 0 0 0 15px rgba(0,210,255,0), 0 0 0 30px rgba(0,210,255,0);
      }
    }
  `,
};

const isMobileDevice = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const ZaloClick = () => {
  let link;
  if (isMobileDevice()) {
    if (navigator.userAgent.includes('Android')) {
      link = 'https://zaloapp.com/qr/p/x9u34t8kkgdc'; // thay doi thong so Zalo
    } else {
      link = 'zalo://qr/p/15nack7b35802';
    }
  } else {
    link = 'zalo://conversation?phone=6584272549';
  }
  window.open(link, '_blank');
};

const ButtonZalo = () => {
  return (
    <div>
      <style>{styles.keyframes}</style>
      <button
        title="Zalo"
        id="nutzalo"
        style={styles.button}
        onMouseOver={e => e.currentTarget.style.opacity = styles.hover.opacity}
        onMouseOut={e => e.currentTarget.style.opacity = 1}
        onClick={ZaloClick}
      >
        ZALO
      </button>
    </div>
  );
};

export default ButtonZalo;
