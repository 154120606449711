// WhatsappButton.js
import React from 'react';

const styles = {
    button: {
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        position: 'fixed',
        bottom: '100px',
        right: '50px',
        border: 'none',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        cursor: 'pointer',
        backgroundColor: '#25D366',  // WhatsApp green color
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
    },
    icon: {
        width: '30px',
        height: '30px',
    },
};

const WhatsappButton = ({ shipmentId }) => {
    const baseMessage = "Hello! I need some assistance about my shipment";
    const message = shipmentId ? `${encodeURIComponent(shipmentId)}%0A${encodeURIComponent(baseMessage)}.` : encodeURIComponent(baseMessage);
    const whatsappUrl = `https://api.whatsapp.com/qr/CHPZGBLBTHRUN1?autoload=1&app_absent=0`; // thay doi thong so whatsapp

    return (
        <button
            title="WhatsApp"
            style={styles.button}
            onClick={() => window.open(whatsappUrl, '_blank')}
        >
            <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/1200px-WhatsApp.svg.png"
                alt="WhatsApp Logo"
                style={styles.icon}
            />
        </button>
    );
};

export default WhatsappButton;
